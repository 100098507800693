import {Stack} from "@mui/material";
import Fussbalcamps from "..\\src\\Fussballcamps.pdf";
import DJKLinkenheim from "..\\src\\DJK Linkenheim.png";

export default function News() {
    return (
        <Stack spacing={2} direction="column" alignItems="center">
            <h2 style={{color: "rgb(255,230,0 )", textShadow: "-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black"}}>News</h2>
            <img src={DJKLinkenheim} style={{width: "75%"}}  alt="Bild für news" ></img>
            <a href={Fussbalcamps} style={{textDecoration: "none", color: "rgb(255,230,0 )", textShadow: "-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black"}}>Unsere Fußballcamps 2025 Übersicht -&gt; (hier klicken)</a>

        </Stack>
    );
}